import React from "react";
import Layout from "../../../other/layout";
import CustomPrinting from "../../../components/AC-StaticPages/products/CustomPrinting";
import SEO from "../../../other/seo";

export default function customPrinting() {
  return (
    <Layout>
      <SEO title="Custom Printing" />

      <CustomPrinting />
    </Layout>
  );
}
