import React from "react";
import { Link } from "gatsby";
import CustomPrinting_Hero from "../../../assets/img/CustomPrinting_Hero.jpg";
import custom_cheques from "../../../assets/img/custom-cheques.jpg";
import custom_forms from "../../../assets/img/custom-forms.jpg";
import custom_deposit_slips from "../../../assets/img/CustomDepositTickets_Thumbnail.jpg";
import custom_envelopes from "../../../assets/img/CustomEnvelopes_Thumbnail.jpg";
import custom_single_sheets from "../../../assets/img/CustomSingleSheet_Thumbnail.jpg";
import custom_specialty_products from "../../../assets/img/CustomSpecialtyProducts_Thumbnail.jpg";

import styles from "../style/styles.module.css";

export default function CustomPrinting() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Custom Printing</h1>
          <h2>Cheques and forms exactly how your customers want them.</h2>
          <p className={styles.productParag}>
            Custom printing for all business needs.
          </p>
          <img
            className={styles.hero}
            src={CustomPrinting_Hero}
            alt="Printing products Image"
          />

          <p className={styles.productMargin}>
            Some customers want to stand out. Others need custom tools that
            boost business efficiency. DFS Custom Solutions can help you say
            'yes' to any custom-printing request, and win more business.
          </p>
          <ul>
            <li>
              Custom printing for cheques, forms, deposit slips, envelopes,
              postcards and more
            </li>
            <li>Exclusive new designs each year</li>
            <li>Low minimum quantities and volume pricing</li>
            <li>Quick quotes, easy ordering and fast turnaround</li>
          </ul>
          <p className={styles.productMargin}>
            We can custom-make nearly any product you need. Call us at{" "}
            <a href="tel:800-490-6327">800.490.6327</a> to get started.
          </p>
          <hr className={styles.productHr} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Product Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={custom_cheques} alt="Custom cheques Image"></img>
              <h3>Custom Cheques</h3>
            </div>
            <div className={styles.card}>
              <img src={custom_forms} alt="Custom forms Image"></img>
              <h3>Custom Forms</h3>
            </div>
            <div className={styles.card}>
              <img
                src={custom_deposit_slips}
                alt="Custom deposit slips Image"
              ></img>
              <h3>Custom Deposit Slips</h3>
            </div>
            <div className={styles.card}>
              <img src={custom_envelopes} alt="Custom envelopes Image"></img>
              <h3>Custom Envelopes</h3>
            </div>
            <div className={styles.card}>
              <img
                src={custom_single_sheets}
                alt="Custom single sheets Image"
              ></img>
              <h3>Custom Single Sheets</h3>
            </div>
            <div className={styles.card}>
              <img
                src={custom_specialty_products}
                alt="Custom specialty products Image"
              ></img>
              <h3>Custom Specialty Products</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
